import { datadogRum } from '@datadog/browser-rum';

import { environment } from 'config/environment';

datadogRum.init({
	applicationId: environment?.datadogApplicationId,
	clientToken: environment?.datadogClientToken,
	service: 'if-cms',
	env: 'uat',
	site: 'us5.datadoghq.com',
	version: '1.0.0',
	trackUserInteractions: true,
	trackResources: true,
	sessionSampleRate: 100,
	traceSampleRate: 100,
	sessionReplaySampleRate: 100,
	defaultPrivacyLevel: 'mask-user-input'
});
